<template>
    <section class="section">
        <h1 class="title is-1">Dashboard</h1>
        <hr>
        <div class="content">
            <p>Comming Soon!</p>
        </div>
    </section>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>