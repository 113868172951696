<template>
    <div class="columns is-vcentered">
        <div class="column is-narrow">
            <div class="has-text-centered">
                <font-awesome-icon class="grip has-text-right" :icon="['far', 'grip-vertical']"/>
            </div>
        </div>
        <div class="column pl-0">
            <div class="field has-addons">
                <p class="control has-icons-left">
            <span class="select">
              <select v-model="icon" aria-label="Social Media Network">
                <option v-for="(selection, i) in iconSelection"
                        :key="`icon-selection-${i}-${selection}`"
                        :value="selection">
                  {{formatIconString(selection)}}
                </option>
              </select>
            </span>
                    <span v-if="icon" class="icon is-small is-left">
                <font-awesome-icon :icon="['fab', icon]"/>
            </span>
                </p>
                <p class="control is-expanded">
                    <input v-model="datum"
                           class="input"
                           type="text"
                           aria-label="Data that goes along with icon"
                           :placeholder="placeholder || ''">
                </p>
                <p class="control">
                    <button @click="deleteItem" class="button is-danger is-outlined">
                        <font-awesome-icon :icon="['fal', 'trash']" />
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
          item: {
            type: Object,
            required: true
          },
          iconKey: {
            type: String,
            required: true
          },
          datumKey: {
            type: String,
            required: true
          },
          iconSelection: {
            type: Array,
            required: true
          },
          placeholder: {
            type: String
          }
        },
        data() {
            return {
                icon: null,
                datum: null
            }
        },
        mounted() {
          let { item, iconKey, datumKey} = this;

          this.icon = item[iconKey];
          this.datum = item[datumKey];
        },
        methods: {
          formatIconString: function (string) {
            return string
            .split('-')
            .map(word => word.split('').map((letter, i) => {
              if (i === 0) return letter.toUpperCase();
              return letter;
            }).join(''))
            .join('');
          },
          deleteItem: function () {
            let { icon, iconKey, datumKey, datum } = this;

            this.$emit('deleteItem', {
              [iconKey]: icon,
              [datumKey]: datum
            });
          }
        },
        watch: {
          datum: function (newDatum, oldDatum) {
            if (oldDatum === null) return;

            let { icon, iconKey, datumKey } = this;
            let event = {
              replaces: icon,
              replacement: {
                [iconKey]: icon,
                [datumKey]: newDatum
              }
            };
            this.$emit('updateItem', event);
          },
          icon: function (newIcon, oldIcon) {
            if (oldIcon === null) return;

            let { datum, iconKey, datumKey } = this;
            let event = {
              replaces: oldIcon,
              replacement: {
                [iconKey]: newIcon,
                [datumKey]: datum
              }
            };
            this.$emit('updateItem', event);
          }
        }
    }
</script>

<style scoped>

</style>