<template>
    <div class="field">
        <label for="editor" class="label">{{label}}</label>
        <div id="editor" class="editor">
            <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                <div class="menubar level">

                    <div class="level-left">
                        <div class="level-item">
                            <div class="field has-addons">
                                <div class="control">
                                    <button class="button"
                                            :class="{ 'is-active': isActive.bold() }"
                                            @click="commands.bold">
                                        <span class="icon is-small"><font-awesome-icon :icon="['fal', 'bold']" /></span>
                                        <span>Bold</span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button"
                                            :class="{ 'is-active': isActive.italic() }"
                                            @click="commands.italic">
                                        <span class="icon is-small"><font-awesome-icon :icon="['fal', 'italic']" /></span>
                                        <span>Italic</span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button"
                                            :class="{ 'is-active': isActive.underline() }"
                                            @click="commands.underline">
                                        <span class="icon is-small"><font-awesome-icon :icon="['fal', 'underline']" /></span>
                                        <span>Underline</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="level-item">
                            <div class="field has-addons">
                                <div class="control">
                                    <button class="button"
                                            :class="{ 'is-active': isActive.paragraph() }"
                                            @click="commands.paragraph">
                                        <span class="icon is-small"><font-awesome-icon :icon="['fal', 'paragraph']" /></span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button"
                                            :class="{ 'is-active': isActive.bullet_list() }"
                                            @click="commands.bullet_list">
                                        <span class="icon is-small"><font-awesome-icon :icon="['fal', 'list']" /></span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button"
                                            :class="{ 'is-active': isActive.ordered_list() }"
                                            @click="commands.ordered_list">
                                        <span class="icon is-small"><font-awesome-icon :icon="['fal', 'list-ol']" /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div class="level-right"></div>-->
                </div>
            </editor-menu-bar>

            <editor-content class="content" :editor="editor" />
        </div>
    </div>
</template>

<script>
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
    import {
        Blockquote,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        Bold,
        Italic,
        Underline,
        History,
    } from 'tiptap-extensions'

    export default {
        components: {
            EditorContent,
            EditorMenuBar
        },
        props: {
            html: String,
            label: String
        },
        data() {
            return {
                editor: new Editor({
                    extensions: [
                        new ListItem(),
                        new Blockquote(),
                        new BulletList(),
                        new HorizontalRule(),
                        new OrderedList(),
                        new Bold(),
                        new Italic(),
                        new Underline(),
                        new History(),
                    ],
                    content: this.html,
                    onUpdate: this.updated
                }),
            }
        },
        methods: {
            updated: function (editedContent) {
                console.log('EDITED CONTENT: ', editedContent);
                this.$emit('updated', editedContent.getHTML());
            }
        },
        beforeDestroy() {
            this.editor.destroy()
        },
    }
</script>