<template>
  <section class="is-relative min-vh-100">
    <div v-if="!section.attributes || loading" class="is-overlay is-flex is-absolute-center">
      <font-awesome-icon spin :icon="['fal', 'spinner-third']" size="3x"/>
    </div>
    <div v-else>
      <h3 class="title is-3">{{section.title}}</h3>
      <hr>
      <div class="columns">
        <div class="column">
          <text-input v-if="section.attributes"
                      class="box"
                      label="Title"
                      @input="updateTitle"
                      :value="section.attributes.title" :key="titleKey" />
          <div class="box">
            <draggable v-model="interventions"
                       @end="updateSort"
                       handle=".grip"
                       ghost-class="gripping"
                       direction="horizontal"
                       :swapThreshold="0.5">

            </draggable>
          </div>
        </div>
        <div class="column">
          <interventions v-if="section.attributes"
                         v-bind="section.attributes" />
        </div>
      </div>
      <div class="buttons is-right">
        <button v-if="formIsDirty"
                @click="cancelEdits"
                class="button is-warning is-light">
          Cancel
        </button>
        <button class="button is-link is-light"
                :disabled="!formIsDirty" @click.prevent="saveChanges">
          Save
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {getSection, updateSection} from '@/utils/api';
import Draggable from 'vuedraggable';
import Interventions from '../interventions';
import TextInput from '../form/TextInput';

export default {
  components: {
    Interventions,
    TextInput,
    Draggable
  },
  data() {
    return {
      titleKey: Math.random().toString(36).substring(2, 15),
      formIsDirty: false,
      original: {},
      section: {},
      loading: true,
      interventions: []
    }
  },
  mounted() {
    let { token } = window.netlifyIdentity.currentUser();

    getSection('interventions', token.access_token)
      .then(section => {
        this.original = section;
        this.section = JSON.parse(JSON.stringify(section));
        this.interventions = section.attributes.interventions;
        this.loading = false;
      });
  },
  methods: {
    updateTitle: function (e) {
      let { val } = e;
      let tempSection = JSON.parse(JSON.stringify(this.section));
      tempSection.attributes.title = val;

      this.section = tempSection;
    },
    cancelEdits: function () {
      this.section = JSON.parse(JSON.stringify(this.original));
      this.interventions = JSON.parse(JSON.stringify(this.original.attributes.interventions));

      //Force re-render of text edit
      this.titleKey = Math.random().toString(36).substring(2, 15);
    },
    saveChanges: function () {
      let { token = {} } = window.netlifyIdentity.currentUser();
      let { access_token } = token;

      this.loading = true;

      updateSection({
        token: access_token,
        component: 'interventions',
        attributes: JSON.parse(JSON.stringify(this.section.attributes))
      })
      .then(res => {
        this.original = res.data.sections.find(section => section.component === 'interventions');
        this.section = JSON.parse(JSON.stringify(this.original));
        this.$emit('sectionUpdated', res.data);
      })
      .catch(err => alert(err.message))
      .finally(() => {
        this.loading = false;
      });
    },
    updateSort: function () {
      let section = JSON.parse(JSON.stringify(this.section));

      section.interventions = this.interventions;

      this.section = section;
    }
  },
  watch: {
    section: function () {
      this.formIsDirty = JSON.stringify(this.section) !== JSON.stringify(this.original);
    }
  }
}
</script>

<style scoped>

</style>