<template>
    <div class="is-overlay has-background-dark-transparent max-h-100vh has-text-white z-index-100 is-flex is-absolute-center">
      <font-awesome-icon spin size="3x" :icon="['fal', 'spinner-third']" />
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>