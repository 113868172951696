<template>
    <layout :settings="settings">
        <router-view @sectionUpdated="updateSettings"
                     class="section py-5">

        </router-view>
    </layout>
</template>

<script>
    import Layout from '../components/admin/Layout';
    import { getSettings } from '../utils/api';
    import AOS from "aos";
    export default {
        components: {
          Layout
        },
        data() {
          return {
              settings: {}
          }
        },
        mounted() {
            let { token = {} } = window.netlifyIdentity.currentUser();
            let { access_token } = token;

            if (!access_token) {
                this.$router.push('/?err=403');
            }

            getSettings(access_token)
                .then(json => {
                    this.settings = json
                    this.$nextTick()
                        .then(() => {
                            AOS.init({
                                easing: 'ease-out',
                                duration: 500
                            });
                        });
                });
        },
        methods: {
            updateSettings: function (e) {
                this.settings = JSON.parse(JSON.stringify(e));
            }
        }
    }
</script>

<style scoped>

</style>