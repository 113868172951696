<template>
    <div>
        Contact!
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>