<template>
    <aside class="menu min-h-100 min-vh-100 section px-0 py-5">
        <ul class="menu-list">
            <li>
                <router-link active-class="is-active" to="/admin" exact>
                    <font-awesome-icon :icon="['fal', 'tachometer-alt-slow']" />
                    &nbsp;
                    <span>Dashboard</span>
                </router-link>
            </li>
        </ul>

        <ul class="menu-list">
            <li>
                <router-link active-class="is-active" to="/admin/app-header">
                    <font-awesome-icon :icon="['fal', 'border-top']" />
                    &nbsp;
                    <span>Header</span>
                </router-link>

                <draggable class="menu-list"
                           v-model="appSections"
                           @end="emmitSort"
                           handle=".grip"
                           ghost-class="gripping"
                           direction="horizontal"
                           :swapThreshold="0.5">
                    <li v-for="section in appSections"
                        :key="section.id">
                        <router-link active-class="is-active"
                             class="no-wrap"
                             :to="`/admin/${section.component}`">
                            <font-awesome-icon class="grip" :icon="['far', 'grip-vertical']" />
                            &nbsp;
                            <span>{{section.title}}</span>
                        </router-link>
                    </li>
                </draggable>
            </li>
            <li>
                <router-link active-class="is-active" to="/admin/app-footer">
                    <font-awesome-icon :icon="['fal', 'border-bottom']" />
                    &nbsp;
                    <span>Footer</span>
                </router-link>
            </li>
        </ul>
    </aside>
</template>

<script>
    import Draggable from 'vuedraggable';

    export default {
        components: {
            Draggable
        },
        props: {
            sections: Array
        },
        data() {
            return {
                appSections: []
            }
        },
        mounted() {
            this.appSections = [...this.sections];
        },
        methods: {
            emmitSort: function () {
                this.$emit('setSort', this.appSections.map(section => section.id));
            }
        }
    }
</script>

<style scoped>
    .menu-list {
        list-style: none;
    }
    .menu-list li .menu-list {
        border-left: 1px solid #dbdbdb;
        margin: .75em;
        padding-left: .75em;
    }
</style>