<template>
    <div class="field">
        <label for="social-medias-panel" class="label is-flex align-baseline justify-between">
            <span>{{label}}</span>
            <button @click="append" class="button is-success is-light is-small">
                <span class="icon"><font-awesome-icon :icon="['fal', 'plus-circle']" size="lg"/></span>
                <span>add</span>
            </button>
        </label>
        <div id="social-medias-panel">
            <draggable class="menu-list"
                       v-model="iconList"
                       @end="emmitSorted"
                       handle=".grip"
                       ghost-class="gripping"
                       direction="horizontal"
                       :swapThreshold="0.5">
                <icon-list-item v-for="(item, i) in iconList"
                              :key="`icon-list-${i}-${item[iconKey]}`"
                              @deleteItem="deleteItem"
                              @updateItem="updateItem"
                              :datum-key="datumKey"
                              :icon-key="iconKey"
                              :icon-selection="iconSelection"
                              :item="item" />
            </draggable>
        </div>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
    import IconListItem from './IconListItem';

    export default {
        components: {
          IconListItem,
          Draggable
        },
        props: {
          list: {
            type: Array,
            required: true
          },
          label: String,
          iconSelection: {
            type: Array,
            required: true
          },
          iconKey: {
            type: String,
            required: true
          },
          datumKey: {
            type: String,
            required: true
          },
        },
        data() {
            return {
                iconList: []
            }
        },
        mounted() {
            this.iconList = this.list.slice();
        },
        methods: {
          emmitSorted: function () {
            this.$emit('sorted', this.iconList)
          },
          deleteItem: function (e) {
            this.$emit('deletedItem', e);
          },
          append: function () {
            let { iconSelection, iconList, iconKey, datumKey } = this;
            let unusedAccounts = iconSelection
              .filter(icon => !iconList.map(item => item[iconKey]).includes(icon));

            let appendedItem = {
                [iconKey]: unusedAccounts[0] || '',
                [datumKey]: ''
            }
            this.iconList.push(appendedItem);
            this.$emit('appendedItem', appendedItem);
          },
          updateItem: function (e) {
            let {iconList, iconKey, datumKey } = this;

            this.iconList = iconList.map(item => {
              if (item[iconKey] === e.replaces) {
                  return {
                      [iconKey]: e.replacement[iconKey],
                      [datumKey]: e.replacement[datumKey]
                  };
              }

              return item;
            })
            this.$emit('updatedItem', e);
          }
        },
    }
</script>