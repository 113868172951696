<template>
    <section class="is-relative min-vh-100">
        <div v-if="!section.attributes || loading" class="is-overlay is-flex is-absolute-center">
            <font-awesome-icon spin :icon="['fal', 'spinner-third']" size="3x"/>
        </div>
        <div v-else class="columns">
            <div class="column is-5">
                <h3 class="title is-3">{{section.title}}</h3>
                <hr>

                <form v-if="section.attributes" action="#" @submit="submit">
                    <text-input v-for="(attrVal, attrName) in section.attributes"
                                :label="prettyPrint(attrName)"
                                :value="attrVal"
                                @input="updateSection"
                                :identifier="attrName"
                                class="box"
                                :key="attrName" />

                    <div class="buttons is-right">
                        <button v-if="formIsDirty"
                                @click="cancelEdits"
                                class="button is-warning is-light">
                            Cancel
                        </button>
                        <button class="button is-link is-light"
                                type="submit"
                                :disabled="!formIsDirty">
                            Save
                        </button>
                    </div>
                </form>
            </div>

            <div class="column is-7">
                <h3 class="title is-3">Preview</h3>
                <hr>
                <app-header v-if="section.attributes" v-bind="section.attributes" />
            </div>
        </div>
    </section>
</template>

<script>
    import {getSection, updateSection} from '../../utils/api';
    import AppHeader from '../app-header';
    import TextInput from '../form/TextInput';

    export default {
        components: {
            AppHeader,
            TextInput
        },
        data() {
            return {
                loading: true,
                section: {},
                formIsDirty: false,
                original: {}
            }
        },
        mounted() {
            let { token } = window.netlifyIdentity.currentUser();

            getSection('app-header', token.access_token)
                .then(section => {
                    this.original = section;
                    this.section = JSON.parse(JSON.stringify(section));
                    this.loading = false;
                });
        },
        methods: {
            prettyPrint: function (str) {
                return str
                    .split('-').map(word => {
                        let first = word[0];
                        let rest = word.split('').slice(1).join('');
                        return first.toUpperCase() + rest;
                    })
                    .join(' ');
            },
            updateSection: function (e) {
                let { val, id } = e;
                let newSection = {...this.section};

                newSection.attributes[id] = val;
                this.section = newSection;
            },
            submit: function (e) {
                e.preventDefault();
                this.loading = true;

                let { token = {} } = window.netlifyIdentity.currentUser();
                let { access_token } = token;

                updateSection({
                    token: access_token,
                    component: 'appHeader',
                    attributes: JSON.parse(JSON.stringify(this.section.attributes))
                })
                .then(res => {
                    this.original = res.data.appHeader;
                    this.section = JSON.parse(JSON.stringify(res.data.appHeader));
                    this.$emit('sectionUpdated', res.data);
                })
                .catch(err => alert(err.message))
                .finally(() => {
                    this.loading = false;
                })
            },
            cancelEdits: function () {
                this.section = JSON.parse(JSON.stringify(this.original));
            }
        },
        watch: {
            section: function () {
                this.formIsDirty = JSON.stringify(this.section) !== JSON.stringify(this.original);
            }
        }
    }
</script>

<style scoped>

</style>