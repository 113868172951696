<template>
    <div class="field">
        <label :for="id" class="label">{{label}}</label>
        <div class="control">
            <component ref="input"
                       :is="isLong ? 'textarea' : 'input'"
                       :id="id"
                       :name="name"
                       :value="value"
                       @input="$emit('input', {val: $event.target.value, id: identifier})"
                       :class="isLong ? 'textarea' : 'input'"
                       :type="isLong ? 'textarea' : 'text'"
                       :placeholder="placeholder">{{value}}</component>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: String,
            label: String,
            placeholder: String,
            name: String,
            identifier: String
        },
        data() {
            return {
                id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                isLong: this.value.length > 50
            }
        },
        watch: {
            value: function (newVal) {
                let oldIsLong = this.isLong;
                this.isLong = newVal.length > 50;

                if (oldIsLong !== this.isLong) {
                    this.$nextTick()
                        .then(() => {
                            this.$refs['input'].focus();
                            this.moveCursorToEnd(this.$refs['input']);
                        });
                }
            }
        },
        methods: {
            moveCursorToEnd: function(el) {
                if (typeof el.selectionStart === 'number') {
                    el.selectionStart = el.selectionEnd = el.value.length;
                } else if (typeof el.createTextRange !== 'undefined') {
                    el.focus();
                    let range = el.createTextRange();
                    range.collapse(false);
                    range.select();
                }
            }
        }
    }
</script>

<style scoped>

</style>