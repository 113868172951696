<template>
    <nav class="navbar is-link" role="navigation" aria-label="main navigation">
        <div class="container-fluid">
            <div class="navbar-brand">
                <a class="navbar-item" href="https://bulma.io">
                    <img src="/og_icon.png" height="28" alt="Thornton Therapeutic">
                </a>

                <span class="navbar-item">Admin</span>

                <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>