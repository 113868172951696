<template>
    <div>
        <admin-nav />
        <div class="container-fluid">
            <spinner v-if="isLoading" />
            <div class="columns">
                <div class="column is-2 has-background-link-light">
                    <side-menu @setSort="sortSections" v-if="settings.sections" :sections="settings.sections" />
                </div>
                <div class="column is-10">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SideMenu from './SideMenu';
    import AdminNav from './AdminNav';
    import {updateSectionOrder} from '@/utils/api';
    import Spinner from '@/components/Spinner';

    export default {
        props: {
            settings: Object
        },
        components: {
            SideMenu,
            AdminNav,
            Spinner
        },
      data() {
        return {
          isLoading: false
        }
      },
      methods: {
          sortSections: async function (sectionsArray) {
            this.isLoading = true;

            let { token } = window.netlifyIdentity.currentUser();

            let res = await updateSectionOrder({token: token.access_token, sectionsArray});

            this.isLoading = false;

            console.log('SORT SECTIONS RES: ', res);
          }
      }
    }
</script>

<style scoped>

</style>